import React, { useState, useEffect,useContext } from 'react'
import { IoMenu } from "react-icons/io5";
// import { IoIosNotificationsOutline } from "react-icons/io";
import { PiCalendarCheckLight } from "react-icons/pi";
import { IoPeopleOutline } from "react-icons/io5";
import { AiOutlineLineChart } from "react-icons/ai";
import { HiOutlinePhone } from "react-icons/hi2";
import { IoLogOutOutline } from "react-icons/io5";
import { ReactComponent as Bell } from '../assets/home/bell-dot.svg'
import { AuthContext } from '../contexts/useAuth';
import {useNavigate} from 'react-router-dom';

export default function Header() {
    const navigate = useNavigate();
    const [menu, setMenu] = useState(false)
    const {handleSignOut} = useContext(AuthContext)
    const menuItems = [
        {
            icon: <Bell />,
            text: "Notifications",
            onClickFunction: ()=>navigate('/notifications')
        },
        {
            icon: <PiCalendarCheckLight />,
            text: "Appointments",
            onClickFunction: ()=>navigate('/appointments')
        },
        {
            icon: <IoPeopleOutline />,
            text: "Professionals",
            onClickFunction: ()=>navigate('/professionals')

        },
        {
            icon: <AiOutlineLineChart />,
            text: "Sales",
            onClickFunction: ()=>navigate('/sales')
        },
        {
            icon: <HiOutlinePhone />,
            text: "Contact us",
            onClickFunction: ()=>navigate('/contact')
        },
        {
            icon: <IoLogOutOutline />,
            text: "Logout",
            onClickFunction: ()=>handleSignOut() 
            
        },
    ]
    const handleClickOutside = (event) => {
        if (menu && !event.target.closest('#menu')) {
          setMenu(false);
        }
      };
    
      // Add event listener on component mount, remove on unmount
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [menu]);
    return (
        <>

            <div className="flex justify-between py-5 px-5  items-center overflow-hidden text-white bg-black flex-grow-0">
                <button className='text-3xl z-index-100' onClick={() => setMenu(!menu)}>

                    <IoMenu className='text' />
                </button>
                <div className="text-xl font-michroma">
                    STYLIN
                </div>

            </div>

            <div id='menu' className={`absolute bg-white text-black top-0 h-screen py-5 left-0 z-index-20 transition duration-300 ease-in-out ${menu ? 'translate-x-0' : '-translate-x-full'
                }`}>
                <div className="text-xl font-michroma mx-12 mb-20">
                    STYLIN
                </div>
                <div className='text-2xl'>
                    {menuItems.map(item => {
                        // const tag = item.icon;
                        return (<div className='flex items-center py-2 px-3 gap-2.5' onClick={()=>{item.onClickFunction();setMenu(false)}}>
                            {item.icon}
                            <button className='text-black text-sm'>
                                {item.text}
                            </button>
                        </div>
                        )
                    })}
                </div>
            </div>
        </>
        // <div className='bg-black w-50 flex justify-between items-center h-[84px] px-33 '>
        //     <div className='ml-20' style={{
        //         fontFamily: "Michroma",
        //         fontSize: "32px",
        //         fontWeight: 400,
        //         lineHeight: "40px",
        //         letterSpacing: "0.12em",
        //         textAlign: "left",
        //         color:"white"
        //     }}>STYLIN</div>
        //     <div className='text-lexend text-white flex gap-3 mr-20 p-3'>
        //         <div className='w-[110px] cursor-pointer'>About</div>
        //         <div className='w-[110px] cursor-pointer'>Features</div>
        //         <div className='w-[110px] cursor-pointer'>Blogs</div>
        //         <div className='w-[110px] cursor-pointer'>Contact</div>
        //     </div>
        // </div>
    )
}
