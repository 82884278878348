import React, { useState } from 'react';
import landing1 from '../assets/landing/landing1.png';
import landing2 from '../assets/landing/landing2.png';
import { FiPhoneCall } from "react-icons/fi";
import Auth from './Auth';

export default function Landing() {
  const [screen, setScreen] = useState(1);
  const [auth, setAuth] = useState(false);

  const handleLogin = () => {
    setAuth(true);
  };

  const handleRegister = () => {
    // Registration logic here
    console.log("register initiated");
    setAuth(true);
  };

  return (
    <>
      <div
        className={`${auth ? 'opacity-50' : ''} flex flex-col h-screen text-white`}
      >
        {/* Header */}
        <div className="bg-black text-center text-2xl h-16 py-4 font-michroma">
          STYLIN
        </div>

        {/* Need Help Bar */}
        {screen === 2 && (
          <div className="bg-black flex items-center justify-center text-sm py-2">
            <div className="bg-themeYellow text-black rounded-full flex justify-center items-center mr-1 h-9 w-9">
              <FiPhoneCall className="text-xl" />
            </div>
            Need Help? Call us now at 7568242321
          </div>
        )}

        {/* Image Container */}
        <div
          className="flex-grow bg-cover bg-center relative"
          style={{
            backgroundImage: `url(${screen === 1 ? landing1 : landing2})`,
          }}
        >
          {/* Overlay to darken the image */}
          <div className="absolute inset-0 bg-black opacity-50"></div>

          {/* Overlaid Text */}
          {screen === 2 && (
            <div className="absolute inset-0 flex items-center justify-center z-10 text-lg">
              Partner with <span className="font-michroma">STYLIN</span>
            </div>
          )}
        </div>

        {/* Buttons and Links */}
        <div className="flex flex-col bg-black gap-3 m-2.5 px-7 py-5">
          <button
            className="bg-themeYellow px-4 py-2 rounded-md text-lg text-black font-semibold"
            onClick={() => {
              if (screen === 2) handleRegister();
              else handleLogin();
            }}
          >
            {screen === 1 ? 'Login' : 'Register Now'}
          </button>
          <button
            onClick={() => {
              setScreen(2);
              if (screen === 2) handleLogin();
            }}
            className="text-white px-4 py-2 rounded-md text-lg border border-white"
          >
            {screen === 1 ? 'Partner with us' : 'Login'}
          </button>
          <div className="flex justify-center w-full text-[#C8C8C8]">
            <small className="px-3 py-1 w-[146px] text-center text-[10px]">
              Terms & Conditions
            </small>
            <small className="px-3 py-1 w-[146px] text-center text-[10px]">
              Privacy Policy
            </small>
          </div>
        </div>
      </div>
      {auth && <Auth setAuth={setAuth} />}
    </>
  );
}
