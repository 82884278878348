import React, { useState,useContext } from 'react'
import { GoHomeFill, GoHome } from "react-icons/go";
import { PiCalendarCheckLight } from "react-icons/pi";
import { FaCalendarCheck } from "react-icons/fa6";
import { IoPeopleOutline, IoPeople } from "react-icons/io5";
import { AiOutlineLineChart } from "react-icons/ai";
import { useNavigate, useLocation } from 'react-router-dom';

export default function NavBar() {
    const navigate = useNavigate()
    const location = useLocation()
    const navItems = [
        {
            id:1,
            selectedIcon: <GoHomeFill />,
            unSelectedIcon: <GoHome />,
            text: "Home",
            link: '/home'
        },
        {
            id:2,
            selectedIcon: <FaCalendarCheck />,
            unSelectedIcon: <PiCalendarCheckLight />,
            text: "Appointment",
            link: '/appointments'
        },
        {
            id:3,
            selectedIcon: <AiOutlineLineChart />,
            unSelectedIcon: <AiOutlineLineChart />,
            text: "Sales",
            link: '/sales'
        },
        {
            id:4,
            selectedIcon: <IoPeople />,
            unSelectedIcon: <IoPeopleOutline />,
            text: "Professionals",
            link: '/professionals'
        },

    ]
    console.log(location.pathname==navItems[0].link)

    const handleNav = (item)=>{
        navigate(`${item.link}`)
    }
    return (
        <div className='flex justify-evenly text-white bg-black pt-2 px-3 pb-4 gap-6 absolute bottom-0 w-full z-index-0'>
            {navItems.map(item => (
                <button className={`gap-1 flex flex-col items-center ${item.link == location.pathname ? 'text-themeYellow' : 'text-white'}`} onClick={() => handleNav(item)}>
                    <div className='text-xl'>
                        {location.pathname === item.link ? item.selectedIcon : item.unSelectedIcon}
                    </div>
                    <div className='text-xs'>
                        {item.text}
                    </div>
                </button>
            ))}
        </div>
    )
}
