import React, { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from '../contexts/useAuth.jsx';
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import sideImage from '../assets/sideImageLogin.jpeg';
import axios from 'axios';
import { loginPage } from "../data/login.js";
import { registerPage } from "../data/register.js";
import { RxCross1 } from "react-icons/rx";
import Input from '../components/Input.jsx';
import {toast} from 'react-toastify';
import { useNavigate } from 'react-router-dom';
export default function Auth({ setAuth }) {
    
    const navigate = useNavigate();
    const { handleSignIn, handleSignUp} = useContext(AuthContext)
    const [otpSent, setOtpSent] = useState(false)
    const { isLoginScreen, handleIsLoginScreen } = useContext(AuthContext);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp,setOtp] = useState('')
    const [errorMessage, setErrorMessage] = useState('');
    const auth = isLoginScreen ? loginPage : registerPage;
    const API_ENDPOINT = process.env.REACT_APP_API_URL;

    const formRef = useRef(null);
    
    // const sendOtp = async(phoneNumber) => {
    //     // setOtp(123456)
    //     const requestData ={
    //         "phoneNumber": phoneNumber,
    //         "is_partner": true
        
    //     }
    //     try {
    //         const response = await axios.post(`${API_ENDPOINT}/auth/signInWithNumber`, requestData, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 accept: "/",
    //                 "Access-Control-Allow-Origin": "*",
    //             },
    //         });
    
    //         setOtp(Number(response.data.otp));
            
    //         if (response.status === 200) {
    //             toast.success("Otp sent Successfully");
    //             setOtpSent(true);
    //         }
    //     } catch (error) {
    //         console.log('error:', error.response);
    
    //         if (error.response) {
    //             if (error.response.status === 404) {
    //                 console.log("account does not exist")
    //                 toast.error('Error occurred');
    //             } else if (error.response.status === 409) {
    //                 toast.error("Account already exists");
    //             } else {
    //                 toast.error(error.response.data?.message || "Sign up failed. Please try again later.");
    //             }
    //         } else { 
    //             toast.error("Something went wrong. Please try again later.");
    //         }
    //     }
    // }
    const handleSubmit = async(e) => {
        // console.log(process.env.BACKEND)
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());
        // console.log(data)
        if (!isLoginScreen) {
            if (data.name === '') setErrorMessage('Name not entered!');
            else if (data.email === '') setErrorMessage('Email not entered');
            else if (data.phoneNumber === '') setErrorMessage('Phone Number not entered!');
            else {
                let emailAtTheRate = data.email.indexOf('@');
                let emailDot = data.email.lastIndexOf('.');
                if (emailDot !== -1 && emailAtTheRate !== -1 && emailAtTheRate < emailDot) {
                    if ((data.phoneNumber[0] == 6 || data.phoneNumber[0] == 7 || data.phoneNumber[0] == 8 || data.phoneNumber[0] == 9) && data.phoneNumber.length == 10) {
                        if (e.target.check.checked) {
                            setErrorMessage('')
                            // console.log('sign up done!')
                            // sendPrimaryDetails()
                            //handleSignIn();
                            handleSignUp(data.phoneNumber,data.email,data.name);
                        }
                        else {
                            setErrorMessage('Please read the Terms and Conditions!')
                        }
                    }
                    else {
                        setErrorMessage('Enter a valid Phone Number!');
                    }
                }
                else {
                    setErrorMessage('Enter a valid email address!');
                }
            }
        }
        else {
            if (data.phoneNumber === '') setErrorMessage('Phone Number not entered');
           // else if (data.otp === '') setErrorMessage('Please add the OTP that you got!');
            else {
                if ((data.phoneNumber[0] == 6 || data.phoneNumber[0] == 7 || data.phoneNumber[0] ==8 || data.phoneNumber[0] == 9) && data.phoneNumber.length == 10) {
                    setErrorMessage('');
                    // console.log('login valid')
                    // verifyOTP();
                    // if (otpSent) handleSignIn(data.phoneNumber, data.otp)
                    // else sendOtp(data.phoneNumber)
                    console.log(data.phoneNumber);
                    handleSignIn(data.phoneNumber);
                }
                else {
                    setErrorMessage('Enter a valid Phone Number!');
                }
            }

        }

    };


    const handleChange = (e) => {
        // console.log(e.target, e.target.name)
        if (e.target.name == "phoneNumber") {
            console.log(isNaN(Number(e.target.value)));
            if (isNaN(Number(e.target.value))) {
                setErrorMessage("Enter a valid mobile number!")
                e.target.value = phoneNumber;
            }
            else {
                setErrorMessage('');
                setPhoneNumber(e.target.value)
            }
        }
    }

    const handleSwitch = () => {
        setErrorMessage('');
        formRef.current.reset();
        handleIsLoginScreen();
    }
    return (

        <div className="absolute top-28 w-screen px-4">
            <form ref={formRef} onSubmit={handleSubmit} onChange={handleChange} className="flex flex-col gap-6 bg-black py-6 px-4 rounded-lg font-mukta ">

                <div className='flex justify-between'>
                    <h1 className='text-2xl font-semibold text-white font-lexend'>
                        {auth.heading}
                    </h1>
                    <button onClick={() => setAuth(false)} className='text-white'><RxCross1 /></button>

                </div>
                <div>

                    <div className='flex flex-col gap-3'>
                        {auth.inputFields.map(input => <Input key={input.id} input={input} otpSent={otpSent} otp={otp} />)}
                    </div>

                    {isLoginScreen ?
                        <span className='flex items-center'>
                            <span className='grow text-red text-xs font-semibold'>{errorMessage ? errorMessage : null}</span>
                            <span id='resend' className='justify-self-end text-white cursor-pointer hover:text-[#F4C01E] text-xs font-light'>{otpSent && 'Resend OTP'}</span>
                        </span> :
                        <span className='flex flex-col'>
                            <small className='grow text-red '>{errorMessage || <br />}</small>
                            <div className='text-white items-self-end text-xs font-light'><input type="checkbox" className='mr-1 border-radius-10' name='check' />I have read the <a href="/" className='underline underline-offset-2 cursor-pointer hover:text-[#F4C01E]'>Terms and Conditions</a></div>
                        </span>
                    }
                </div>
                <button
                    type="submit"
                    className='text-base font-medium w-full bg-[#F4C01E] font-medium py-3 px-2 rounded-md hover:bg-[#F7C647] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white'
                >
                    {isLoginScreen ? 'Login' : 'Register'}
                </button>
                <span className="text-xs font-light flex gap-1 text-white">
                    <div>
                        {auth.switchLine.question}{' '}
                    </div>
                    <strong
                        onClick={handleSwitch}
                        className=" hover:text-[#F4C01E] cursor-pointer"
                    >
                        {auth.switchLine.actionable}
                    </strong>
                </span>
            </form>
            {/* <img src={auth.sideImage.image} alt={auth.sideImage.alt} className="hidden md:block mb-0 absolute right-0 top-20 h-[89vh] w-1/2 object-cover" /> */}
        </div>

    );
}
