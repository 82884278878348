import React, { useEffect,useContext, lazy, Suspense } from 'react';

import Header from './pages/Header';
import Landing from './pages/Landing';
import NavBar from './pages/NavBar';
import { AuthContext } from './contexts/useAuth';
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
//import { messaging, onMessage } from './firebase';
//import { getToken } from 'firebase/messaging';

// import Home from './pages/Home';
const Home = lazy(() => import("./pages/Home"));
const Appointment = lazy(() => import("./pages/Appointment"));
const Sales = lazy(() => import("./pages/Sales"));
const Professionals = lazy(() => import("./pages/Professionals"));
const Profile = lazy(()=>import("./components/Profile"));
const AddMember = lazy(()=>import("./components/AddMember"));
const Services = lazy(()=>import("./pages/Services"))
const Contact = lazy(()=>import("./pages/Contact"))
const Notifications = lazy(()=>import("./pages/Notifications"))

function App() {
  const navigate = useNavigate();
  const { signedIn } = useContext(AuthContext);
  useEffect(() => {
    if (signedIn && window.location.pathname === '/') {
      navigate('/home');
    }
  }, [navigate, signedIn]);
  
  // let signedIn = true

 
    // async function requestPermission() {
    //   const permission = await Notification.requestPermission();
    //   if (permission === "granted") {
    //     // Generate Token
    //     const token = await getToken(messaging, {
    //       vapidKey:
    //         "BPp2qBoy5hruLw60s9WrZzJe7unBxf7NETFb0i9yiw0-cZ_ZUWr0P2vW0X-OHglEvt6HwYslg70M8Ny7d_3sm4w",
    //     });
    //     console.log("Token Gen", token);
    //     // Send this token  to server ( db)
    //   } else if (permission === "denied") {
    //     alert("You denied for the notification");
    //   }
    // }
  
    // useEffect(() => {
    //   // // Register service worker
    //   // if ('serviceWorker' in navigator) {
    //   //   navigator.serviceWorker.register('./firebase-messaging-sw.js')
    //   //     .then((registration) => {
    //   //       console.log('Service Worker registered with scope:', registration.scope);
    //   //     })
    //   //     .catch((err) => {
    //   //       console.error('Service Worker registration failed:', err);
    //   //     });
    //   // }
  
    //   // Req user for notification permission
    //   requestPermission();
  
    //   // Listen for messages
    //   onMessage(messaging, (payload) => {
    //     console.log('Message received. ', payload);
    //     // Handle the message
    //   });
    // }, [])

  return (
    <div className='max-h-screen overflow-hidden bg-black relative'>
      {
        signedIn &&
      <>
      <NavBar />
      <Header />
      </>
    }
      <Routes>
        <Route index element={<Landing />} />
        <Route path="/home" element={<Suspense fallback={<div className='h-screen'>Loading...</div>}>{signedIn ? <Home />: <Landing/>}</Suspense>} />
        <Route path="/services" element={<Suspense fallback={<div className='h-screen'>Loading...</div>}>{signedIn ? <Services />: <Landing/>}</Suspense>} />
        <Route path="/contact" element={<Suspense fallback={<div className='h-screen'>Loading...</div>}>{signedIn ? <Contact />: <Landing/>}</Suspense>} />
        <Route path="/notifications" element={<Suspense fallback={<div className='h-screen'>Loading...</div>}>{signedIn ? <Notifications />: <Landing/>}</Suspense>} />
        <Route path="/appointments" element={<Suspense fallback={<div className='h-screen'>Loading...</div>}>{signedIn ? <Appointment />: <Landing/>}</Suspense>} />
        <Route path="/sales" element={<Suspense fallback={<div className='h-screen'>Loading...</div>}>{signedIn ? <Sales />: <Landing/>}</Suspense>} />
        <Route path="/professionals" element={<Suspense fallback={<div className='h-screen'>Loading...</div>}>{signedIn ? <Professionals />: <Landing/>}</Suspense>}>
          <Route path="" element={<Professionals />} />  {/* Default route for Professionals */}
          <Route path=":stylistId/profile" element={<Suspense fallback={<div>Loading...</div>}>{signedIn ? <Profile />: <Landing/>}</Suspense>} /> {/* Profile sub-route */}
          <Route path="add-member" element={<Suspense fallback={<div>Loading...</div>}>{signedIn ? <AddMember />: <Landing/>}</Suspense>} /> {/* AddMember sub-route */}
        </Route>
      </Routes>
    </div>
    // <div className='h-screen flex flex-col justify-center items-center  '>
    // <div className='font-bold text-xl text-white font-lexend text-center'>Can't wait to show you what we got!</div>
    // <button className='bg-themeYellow rounded-full font-semibold text-lg py-2 px-3 m-2'>Sign In</button>
    // </div>}
    // <div className='max-h-screen overflow-hidden bg-black'>
    //   {!signedIn ? <Landing /> :
    //     (
    //       <div className='h-screen relative overflow-hidden'>
    //         <NavBar />
    //         <Header />
    //         {screenIndex == 1 && <Home />}
    //         {screenIndex == 2 && <Appointment id={stylistId}  />}
    //         {screenIndex == 3 && <Sales />}
    //         {screenIndex == 4 && <Professionals />}

    //       </div>)}
    // </div>
  );
}

export default App;
