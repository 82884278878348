import React, {useState, useEffect} from 'react';

const useLocalStorage = (key, defaultVal) => {
    const [value, setValue] = useState(()=>{
        let currentVal;
        try{
            currentVal = JSON.parse(
                localStorage.getItem(key) || String(defaultVal)
            )
        } catch(error) {
            currentVal = defaultVal;
        }

        return currentVal
    });


    useEffect(()=>{
        localStorage.setItem(key,JSON.stringify(value));
    },[value,key]);

    return [value,setValue]
}

export default useLocalStorage;

