export const registerPage = {
    heading: "Create an account",
    inputFields: [
        {
            id:1,
            name:"name",
            placeholder:"Name",
            type:"text",
            // validationRule:
        },
        {
            id:2,
            name: "email",
            type: "email",
            placeholder: "Email",
            required: true

        },
        {
            id:3,
            name: "phoneNumber",
            type: "text",
            placeholder: "Phone Number",
            required: true,
            maxlength:"10",
            pattern:".{10,10}",
            inputMode: "numeric"

        },
    ],
    auxillaryLine: {
        text: "I have read the Terms and Conditions",
        function: () => {
            // console.log("Terms and Conditions");
        }
    },
    buttonText: "Sign Up",
    switchLine: {
        question: "Already have an account?",
        actionable: "Login"
    },
    sideImage:
    {
        image: require('../assets/sideImageRegister.jpeg'),
        alt:"woman getting hair treatment"
    }

}

