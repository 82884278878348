export const loginPage = {
    heading: "Login",
    inputFields: [
        {
            id:1,
            name: "phoneNumber",
            type: "text",
            placeholder: "Phone Number",
            required: true,
            maxlength:"10",
            pattern:".{10,10}",
            inputMode: "numeric"
            // validationRule:
        },
        // {
        //     id:2,
        //     name: "otp",
        //     type: "text",
        //     placeholder: "OTP",
        //     required: true,
        //     inputMode: "numeric",
        //     maxlength:"6",
        //     pattern:".{6,6}",

        // },
    ],
    auxillaryLine: {
        text: "Resend OTP",
        function: () => {
            // console.log("Otp resend request to be sent, not been configured yet!");
        }
    },
    buttonText: "Login",
    switchLine: {
        question: "Don't have an account?",
        actionable: "Register Now"
    },
    sideImage:
    {
        image: require('../assets/sideImageLogin.jpeg'),
        alt:"woman getting facial"
    }

}

