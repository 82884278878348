import React, { useState, createContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import axios from 'axios';
//import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'

const AuthContext = createContext({
  isLoginScreen: true,
  handleIsLoginScreen: () => { },
  signedIn: false,
  handleSignIn: () => { },
  handleSignOut: () => { },
  authToken: null,
  handleShopId: () => { },
  availableStylists: [],
  fetchAvailableStylists: () => { },
})

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoginScreen, setIsLoginScreen] = useState(true);
  const [signedIn, setSignedIn] = useLocalStorage(false)
  const handleIsLoginScreen = () => setIsLoginScreen(!isLoginScreen);
  const API_ENDPOINT = process.env.REACT_APP_API_URL;
  const [shopId, setShopId] = useLocalStorage('shopId', null);
  const [authToken, setAuthToken] = useLocalStorage("authToken", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjMsInR5cGUiOiJwaG9uZU51bWJlciIsImlkZW50aWZpZXIiOiI4Njg4NzQ5NDU4IiwiaWF0IjoxNzE0ODUxOTA3LCJleHAiOjE3MTc0NDM5MDd9.F-_317eDGdlgMhqb7EcHAmXrCtYNUmPBxdAIvubpK1I")
  const [availableStylists, setAvailableStylists] = useState([]);
  const fetchAvailableStylists = async () => {
    if (!shopId || !authToken) return;

    try {
      const res = await axios.get(
        `${API_ENDPOINT}/professional/getAvailableStylist?shop_id=${shopId}`,
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );
      setAvailableStylists(res.data.availableStylists);
    } catch (err) {
      console.error("Error fetching stylists:", err);
    }
  };


  const handleSignUp = async (phoneNumber, email, name) => {
    try {
      const response = await axios.post(`${API_ENDPOINT}/auth/signUpWithNumber`, {
        phoneNumber: phoneNumber,
        email: email,
        name: name,
        "is_partner": true,
      }, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      });
      if (response.status === 200) {
        setAuthToken("Bearer " + response.data.accessToken);
        setSignedIn(true);
        navigate('/home')
      } else {
        console.log("error in singnup")
      }
    } catch (error) {
      console.log(error);
    }


  }
  const handleSignIn = async (phoneNumber) => {
    console.log('sign in tried', phoneNumber);

    try {
      const res = await axios.post(
        `${API_ENDPOINT}/auth/signInWithNumber`,
        {
          phoneNumber: phoneNumber,
          is_partner: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (res.status === 200) {
        setAuthToken("Bearer " + res.data.accessToken);
        setSignedIn(true);
        navigate('/home');
      }
    } catch (error) {
      console.log('error', error);
    }
    console.log('Signed In');
  };

  const handleSignOut = () => {
    setSignedIn(false);
    console.log('Signed Out')
  }
  const handleShopId = (id) => setShopId(id)
  return (
    <AuthContext.Provider value={{
      isLoginScreen,
      handleIsLoginScreen,
      signedIn,
      handleSignIn,
      handleSignUp,
      handleSignOut,
      authToken,
      shopId,
      handleShopId,
      availableStylists,
      fetchAvailableStylists,
    }}
    >
      {children}
    </AuthContext.Provider>

  )
}
export { AuthContext, AuthProvider };